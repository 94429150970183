













import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class TileSliderPlaceholder extends Vue {
    @Prop({ type: String }) parentState: string

    get numberOfTimesToLoop() {
        return this.parentState === 'playlists' ? 5 : 6
    }

    get panelClass() {
        if (this.parentState === 'playlists') return 'tilePanel--playlist'
        return 'tilePanel--producer'
    }
}

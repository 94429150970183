var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tiles-slider__wrapper" }, [
    _c(
      "div",
      { staticClass: "tiles-slider__inner" },
      _vm._l(_vm.numberOfTimesToLoop, function (n) {
        return _c(
          "div",
          {
            key: n,
            staticClass: "tilePanel--placeholder",
            class: _vm.panelClass,
          },
          [
            _vm.parentState === "topProducers" || _vm.parentState === "genres"
              ? _c("div", [
                  _c("div", { staticClass: "tilePanel--img--producer" }),
                  _c("div", {
                    staticClass: "tilePanel__details--track-title--producer",
                  }),
                ])
              : _vm._e(),
            _vm.parentState === "artistTypes"
              ? _c("div", { staticClass: "tilePanel__imgContainer" }, [
                  _c("div", { staticClass: "tilePanel--img--artist" }),
                ])
              : _vm._e(),
            _vm.parentState === "playlists"
              ? _c("div", { staticClass: "tilePanel__imgContainer" }, [
                  _c("div", { staticClass: "tilePanel__img" }),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }